@import "minima";
//@import url('https://fonts.googleapis.com/css?family=Montserrat:300,500&display=swap');
@import url('https://fonts.googleapis.com/css?family=DM+Serif+Display&display=swap');

body {
  background-color: #01010c;
  color: #01010c;
  font-family: 'DM Serif Display', serif;
  background-image: url("https://source.unsplash.com/2560x1440/?forest"), url("images/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  animation-name: fadeIn;
  animation-duration: 2s;
}

.site-header {
  position: absolute;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1), 0 14px 30px 0 rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, .2);
  border: none;
  color: #fff;

  .site-title, a, a:hover, a:visited, a:focus {
    color: #fff;
    text-decoration: none;
  }

  small {
    white-space: nowrap;
  }

  .wrapper {
    display: flex;
    width: 100%;
    padding-left: 60px;
    padding-right: 0;
  }
}

.site-nav {
  float: none;
  display: flex;
  flex-direction: row-reverse;

  .page-link {
    color: #fff;
    text-decoration: none;
  }
}

@media screen and (max-width: 600px) {
  .site-nav {
    a.page-link, a.page-link:hover, a.page-link:visited, a.page-link:focus {
      color: #000;
    }
  }
}

.site-nav-spacing {
  display: flex;
  flex-grow: 1;
}

.site-footer {
  border: 0;
  box-shadow: 0 -12px 12px 0 rgba(0, 0, 0, 0.1), 0px -5px 30px 0 rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, .2);
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-col-wrapper {
  color: #fff;
}

.footer-col {
  margin-bottom: 0;
}

.site-header h1 {
  margin: 0;
  letter-spacing: .02em;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .header h1 {
    flex-direction: column;
    font-size: 28px;
  }

  .site-header {
    padding: 12px 0;
  }
}

blockquote {
  color: #fff;
  position: relative;
  display: flex;
  min-height: 90px;
  border: 0;
  margin: 0;
  padding: 0 24px;
  align-items: center;
  font-size: 30px;
  letter-spacing: .01em;
}

blockquote::before,
blockquote::after {
  position: absolute;
  font-size: 40px;
  height: 24px;
  width: 24px;
  line-height: 1.1;
}

blockquote::before {
  content: '“';
  bottom: 0;
  left: 0;
}

blockquote::after {
  content: '”';
  top: 0;
  right: 0;
}

#napituti {
  padding: 0 24px;
}

.title {
  font-size: 40px;
  letter-spacing: -.02em;
  color: #ffffff;
  margin-bottom: 50px;
}

#tuti-id {
  color: #ffffff;
  float: right;
  font-size: 28px;
}


.page-content {
  display: flex;
  align-items: center;
}

.post {
  display: flex;
  flex-direction: column;
  color: #fff;
  font-size: 24px;
  min-height: 300px;
  align-items: center;
  margin-top: 35px;

  max-width: 1400px;
  width: 100%;
  background-color: rgba(0, 0, 0, .5);
  border-radius: 8px;
  padding: 24px 36px;
  box-shadow: 18px 10px 20px 0 rgba(0, 0, 0, 0.1), -14px -9px 30px 0 rgba(0, 0, 0, 0.2);

  a, a:hover, a:visited, a:focus {
    color: #fff;
    text-decoration: underline;

    &.button {
      text-decoration: none;
    }
  }

  &.post-home {
    align-items: normal;
  }

  .external-links {
    text-align: left;
  }
}

.insta-gallery {
  .insta-photo {
    h2 {
      font-size: 24px;
      margin-bottom: 0;
    }

    width: 48%;
    float: left;
    margin: 1%;
  }
}

.insta-follow-us {
  margin: 15px 0 0;

  a, a:hover, a:visited, a:focus {
    text-decoration: none;
  }
}

.tool-box {
  margin: 0 0 30px;

  &:last-child {
    margin: 0;
  }

  code {
    color: #000;
  }
}

@media only screen and (min-width: 768px) {
  .post {
    min-width: 740px;
  }
}

.post-content {
  margin-bottom: 0;
  min-height: 300px;
  width: 100%;
  position: relative;
}

.buttonContainer {
  margin-top: 50px;
  bottom: 0;
  width: 100%;
  height: 50px;
  min-width: 350px;
}

.button {
  border: 1px solid white;
  background: transparent;
  color: #fff;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 16px;
  margin-right: 24px;
  cursor: pointer;
  transition: 325ms ease-out;
  display: inline-flex;
  transform: none;
  text-decoration: none;
  font-family: Arial;
  line-height: normal;
}

.button:hover, .button:visited, .button:focus {
  background-color: rgba(255, 255, 255, .4);
  transition: 325ms ease-out;
  color: #fff;
  text-decoration: none;
  font-family: Arial;
}

.footer-col p {
  letter-spacing: .03em;
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 30px;
  }

  blockquote {
    font-size: 24px;
    min-height: auto;
  }

  .buttonContainer {
    min-width: auto;
  }

  .post {
    width: initial;
    padding: 12px;
    border-radius: 0px;
    background-color: rgba(0, 0, 0, .2);
    box-shadow: 1px 20px 20px 0 rgba(0, 0, 0, 0.1), 0px -20px 30px 0 rgba(0, 0, 0, 0.2);
  }

  #napituti {
    padding: 0 6px;
  }

  .wrapper {
    padding: 0;
  }

  .footer-col p {
    margin: 0;
    text-align: center;
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

/* Tutikereső */

#tuti-search {
  box-sizing: border-box;
  padding: 10px 5px 10px 10px;
  border: none;
  border-bottom: 1px solid #ddd;
  width: 100%;
  font-size: 18px;
}

.searchbox {
  position: relative;
  display: inline-block;
}

.searchbox-content {
  display: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  min-width: 200px;
  z-index: 1;
}

.searchbox-content div {
  overflow-y: scroll;
  max-height: 260px;
}

.searchbox-content a,
.searchbox-content a:hover,
.searchbox-content a:visited,
.searchbox-content a:focus {
  color: white;
  padding: 5px 10px;
  text-decoration: none;
  display: block;
  font-size: 16px;
  font-family: Arial;
}

.searchbox-content a:hover {
  color: black;
  background-color: rgba(241, 241, 241, 0.7);
}

.searchbox .show {
  display:block;
}
